<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">Enquiries</h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Enquiry</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-4 col-md-4 col-sm-4 col-4">
            <div class="card card-congratulation-medal">
              <div class="card-body pb-50 d-flex justify-content-between" style="position: relative">
                <h4 class="font-weight-bolder mb-1">Total</h4>
                <h4 class="font-weight-bolder mb-1 text-secondary"><b>{{ enquiry_info?.total_enquires ?? 0 }}</b></h4>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-4 col-4">
            <div class="card card-congratulation-medal">
              <div class="card-body pb-50 d-flex justify-content-between" style="position: relative">
                <h4 class="font-weight-bolder mb-1">Converted</h4>
                <h4 class="font-weight-bolder mb-1 text-success"><b>{{ enquiry_info?.total_convert_enquires ?? 0 }}</b></h4>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-4 col-4">
            <div class="card card-congratulation-medal">
              <div class="card-body pb-50 d-flex justify-content-between" style="position: relative">
                <h4 class="font-weight-bolder mb-1">Unconverted</h4>
                <h4 class="font-weight-bolder mb-1 text-danger"><b>{{ enquiry_info?.total_unconvert_enquires ?? 0 }}</b></h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="float-start col-4">
                    <div class="input-group input-group-merge">
                      <span class="input-group-text" id="basic-addon-search2"
                        ><vue-feather type="search"></vue-feather
                      ></span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        aria-label="Search..."
                        aria-describedby="basic-addon-search2"
                        v-model="form.search"
                        @keyup="searchData"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <Datepicker
                      type="date"
                      name="date"
                      @closed="dateChange()"
                      @cleared="dateChange()"
                      v-model="form.rangeDate"
                      calendar-button="false"
                      format="dd-MM-yyyy"
                      value-format="dd-MM-yyyy"
                      modelAuto
                      multiCalendars
                      range
                      multiCalendarsSolo
                      placeholder="Select Date"
                    />
                  </div>
                  <div class="col-4 col-lg-4 col-sm-4">
                    <div class="float-end">
                      <button
                        class="btn btn-gradient-primary mr-1"
                        data-bs-toggle="modal"
                        data-bs-target="#addImport"
                        @click="resetImport()"
                      >
                        Import
                      </button>
                      <button
                        class="btn btn-gradient-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#addInquiry"
                        @click="resetInquiry()"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <section id="ajax-datatable">
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-datatable">
                          <table
                            class="dt-column-search table table-responsive"
                          >
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-show="list.length > 0"
                                v-for="(li, index) in list"
                                :key="li.id" :class="{'fill':li.converts_count > 0}"
                              >
                                <td>{{ index + paginationData.from }}</td>
                                <td style="width: 200px">
                                  {{ li.created_at }}
                                </td>
                                <td>{{ li.name }}</td>
                                <td>{{ li.phone }}</td>
                                <td>{{ li.email }}</td>
                                <td>{{ li.message }}</td>
                                <td style="width: 150px">
                                  <div class="d-flex">
                                  <a target="_blank" :href="getWALink(li.phone)" class="align-items-center btn btn-sm btn-gradient-primary mr-1 text-white">
                                    <i class="fa fa-whatsapp" aria-hidden="true"></i>
                                  </a>
                                  <button
                                    class="align-items-center btn btn-sm btn-gradient-primary float-end"
                                    data-bs-toggle="modal"
                                    data-bs-target="#FollowUpModel"
                                    @click="getFollowUpDetails(li)"
                                  >
                                    <vue-feather type="send"></vue-feather>
                                  </button>
                                  </div>
                                </td>
                              </tr>
                              <tr v-show="list.length === 0">
                                <td colspan="11" class="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6">
                                <Pagination
                                  :data="paginationData"
                                  @pagination-change-page="getResults"
                                  show-disabled="true"
                                  limit="3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        class="modal fade"
        id="addInquiry"
        tabindex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <b>Add Inquiry</b>
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref="closebtn"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row detail_card_wrapper">
                <div class="d-flex flex-column">
                  <div class="mb-1">
                    <label for="addname">Name</label>
                    <input
                      class="form-control"
                      id="addname"
                      placeholder="Enter Name"
                      v-model="addForm.name"
                    />
                    <div class="error text-danger" v-if="errors && errors.name">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                  <div class="mb-1">
                    <label for="addphone">Phone</label>
                    <input
                      type="number"
                      class="form-control"
                      id="addphone"
                      placeholder="Enter Phone"
                      v-model="addForm.phone"
                    />
                    <div
                      class="error text-danger"
                      v-if="errors && errors.phone"
                    >
                      {{ errors.phone[0] }}
                    </div>
                  </div>
                  <div class="mb-1">
                    <label for="addemail">Email</label>
                    <input
                      class="form-control"
                      id="addemail"
                      placeholder="Enter Email"
                      v-model="addForm.email"
                    />
                    <div
                      class="error text-danger"
                      v-if="errors && errors.email"
                    >
                      {{ errors.email[0] }}
                    </div>
                  </div>
                  <div class="mb-1">
                    <label for="addmessage">Message</label>
                    <textarea
                      class="form-control"
                      placeholder="Enter Message"
                      v-model="addForm.message"
                      id="addmessage"
                    ></textarea>
                    <div
                      class="error text-danger"
                      v-if="errors && errors.message"
                    >
                      {{ errors.message[0] }}
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary my-2 w-auto"
                    :disabled="disabled"
                    @click="submitInquiry()"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="addImport"
        tabindex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <b>Import CSV</b>
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref="closebtn"
              ></button>
            </div>
            <div class="modal-body">
              <form method="post" @submit.prevent="importInquiry">
              <div class="row detail_card_wrapper">
                <div class="d-flex flex-column">
                  <div class="mb-1">
                    <label class="float-end"><a :href="inquiry_file" class="text-secondary"><u>Download Sample</u></a></label>
                  </div>
                  <div class="mb-1">
                    <input type="file" id="importCsv" ref="importCsv" class="form-control" name="import_file"  placeholder="CSV/XLSX" @change="importFile" accept=".xls,.xlsx" />
                    <div class="error text-danger" v-if="errors && errors.excel">
                      {{ errors.excel[0] }}
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary my-2 w-auto"
                    :disabled="disabled"
                  >
                    Import
                  </button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="FollowUpModel"
        tabindex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <b>Follow Up Notes</b>
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row detail_card_wrapper">
                <div class="d-flex flex-column">
                  <textarea
                    class="form-control"
                    v-model="form.notes"
                  ></textarea>
                  <div class="error text-danger" v-if="errors && errors.notes">
                    {{ errors.notes[0] }}
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary my-2 w-auto"
                    :disabled="disabled"
                    @click="submitFollowUpDetails()"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div
                class="loader-wrapper text-center justify-content-center"
                v-if="list_loader"
              >
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div class="row detail_card_wrapper">
                <table class="dt-column-search table table-responsive">
                  <thead>
                    <tr>
                      <th>Notes</th>
                      <th style="width: 200px">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-show="note_list.length > 0"
                      v-for="li in note_list"
                      :key="li.id"
                    >
                      <td>{{ li.notes }}</td>
                      <td>{{ li.created_at }}</td>
                    </tr>
                    <tr v-show="note_list.length === 0">
                      <td colspan="16" class="text-center">No Data Found</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Form from "vform";
import { ref } from "vue";
import moment from "moment";

export default {
  name: "Theater",
  components: {
    Pagination: LaravelVuePagination,
    Datepicker,
  },
  data() {
    return {
      inquiry_file: process.env.VUE_APP_BACKEND_URL+'/files/import_enquiry.xlsx',
      addForm: new Form({
        for: "Admin",
        name: "",
        phone: "",
        email: "",
        message: "",
      }),
      form: new Form({
        rangeDate: ref(),
        from_date: "",
        to_date: "",
        search: null,
        inquiry_id: "",
        notes: "",
        excel: "",
      }),
      note_list: [],
      list_loader: true,
      errors: {},
      response: "",
      disabled: false,
      header: [],
      list: [],
      paginationData: {},
      token: localStorage.getItem("auth_token"),
      enquiry_info: null,
    };
  },
  async mounted() {
    this.header = [
      "Sr.",
      "Enquiry Date",
      "Name",
      "Phone",
      "Email",
      "Message",
      "Follow-Up",
    ];

    this.getEnquiryInfo();
    this.getResults();
  },
  methods: {
    importFile(e) {
      let file = e.target.files[0];
      this.form.excel = file;
    },
    resetImport() {
      this.$refs.importCsv.value = "";
      this.disabled = false;
      this.errors = {};
      this.form.excel = "";
    },
    resetInquiry() {
      this.disabled = false;
      this.errors = {};
      this.addForm = {
        for: "Admin",
        name: "",
        phone: "",
        email: "",
        message: "",
      };
    },
    dateChange() {
      this.form.from_date = "";
      this.form.to_date = "";
      if (this.form.rangeDate) {
        const date = this.form.rangeDate;
        if (date.length > 0 && date.length != undefined) {
          date.map(() => {
            this.form.from_date = moment(date[0]).format("YYYY-MM-DD");
            this.form.to_date = moment(date[1]).format("YYYY-MM-DD");
          });
        } else {
          if (typeof date != undefined) {
            this.form.from_date = moment(date).format("YYYY-MM-DD");
            this.form.to_date = moment(date).format("YYYY-MM-DD");
          }
        }
      }
      this.getResults();
    },
    async getEnquiryInfo() {
    
      await axios
        .get(
          process.env.VUE_APP_API_CUS_URL +
            "/get-enquiry-info",
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.enquiry_info = response.data.data;
        });
    },
    async getResults(page = 1) {
      var searchq = "";
      if (this.form.search) {
        searchq += "&search=" + this.form.search;
      }
      if (this.form.from_date) {
        searchq += "&from_date=" + this.form.from_date;
      }
      if (this.form.to_date && this.form.from_date != this.form.to_date) {
        searchq += "&to_date=" + this.form.to_date;
      }

      await axios
        .get(
          process.env.VUE_APP_API_CUS_URL +
            "/enquiries/" +
            "?page=" +
            page +
            searchq,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.paginationData = response.data.data;
        });
      this.list = this.paginationData.data;
    },
    getFollowUpDetails(item) {
      this.form.inquiry_id = item.id;
      this.note_list = item.get_notes;
      this.errors = {};
      setTimeout(() => {
        this.list_loader = false;
      }, 500);
    },
    async submitInquiry() {
      this.errors = {};
      this.disabled = false;

      var apiUrl = process.env.VUE_APP_API_CUS_URL + "/enquiry-store";

      await axios
        .post(
          apiUrl,
          this.addForm,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(response.data?.message);
          }

          this.getResults();
          this.$refs.closebtn.click();

          setTimeout(() => {
            this.resetInquiry();
          }, 500);
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    async importInquiry() {
      this.errors = {};
      this.disabled = false;

      var apiUrl = process.env.VUE_APP_API_CUS_URL + "/enquiry-import";

      await this.form.post(apiUrl,
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${this.token}`
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(response.data?.message);
          }

          this.getEnquiryInfo();
          this.getResults();
          this.$refs.closebtn.click();

          setTimeout(() => {
            this.resetImport();
          }, 500);
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }else {
            this.$toast.error(error.response.data?.message);
          }
        });
    },
    async submitFollowUpDetails() {
      this.errors = {};
      this.disabled = false;

      var apiUrl = process.env.VUE_APP_API_CUS_URL + "/submit-notes";

      await axios
        .post(
          apiUrl,
          {
            id: this.form.inquiry_id,
            notes: this.form.notes,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.disabled = false;
            this.form.notes = "";
            this.$toast.success(response.data?.message);
            if (response.data) {
              this.note_list = response.data.note_list;
            }
          }

          setTimeout(() => {
            this.disabled = false;
          }, 500);
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            // this.$toast.error(response.data?.message);
          }
        });
    },
    async searchData() {
      setTimeout(() => {
        this.getResults();
      }, 200);
    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    getWALink(number)
    {
      return 'https://api.whatsapp.com/send?phone=91'+number.substr(-10);
    }
  },
};
</script>
<style scoped>
/* a {
      position: absolute;
      right: 15px;
      bottom: 15px;
      font-weight: bold;
      text-decoration: none;
      color: #00003a;
      font-size: 20px;
    } */

/*tag input style*/

.tag-input {
  width: 100%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 45px;
  box-sizing: border-box;
  padding: 0 10px;
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #7367f0;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  transition: 0.1s ease background;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.image_list_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.image_list_wrapper .image-box {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 104px;
  display: block;
  position: relative;
}

.delete-btn-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
}

.delete-btn-wrapper .delete-btn {
  color: red;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image_list_wrapper .image-box:hover .delete-btn-wrapper {
  display: flex;
}

/* del button */
.delete-btn {
  background: #7da741;
  color: white;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 0px 3px 9px 0px black;
  border: 1px solid #2bb2cc;
  width: 30px !important;
  height: 30px !important;
}

.delete-btn i {
  color: white;
}

.ecommerce-application .search-product,
.ecommerce-application .input-group-text {
  height: 39px !important;
}

.ecommerce-application .dp__main:deep(.dp__input) {
  height: 39px;
}

.mr-1 {
  margin-right: 15px;
}

i.fa.fa-whatsapp {
    font-size: 24px;
}

.align-items-center {
  display: flex;
  align-items: center;
}

tr.fill {
  background: rgb(40 167 69 / 20%);
}

tr.fill td {
  font-weight: bold;
}

/* end */
</style>
